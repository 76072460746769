<template>
  <div>
    <div class="title">
      <slot name="header">
        <p class="fw-800 f-16">
          {{ showDynamicComponent.title }}
        </p>
      </slot>
    </div>
    <div class="body">
      <div class="word_break">
        {{ showDynamicComponent.desc }}
      </div>
    </div>
    <div class="footer">
      <div class="footer_body">
        <el-button
          v-if="showDynamicComponent.cancelButton"
          :class="showDynamicComponent.confirmButton ? 'btn-default' : 'btn_blue'"
          @click="close"
        >
          {{ showDynamicComponent.cancelButton }}
        </el-button>
        <el-button v-if="showDynamicComponent.confirmButton" class="btn_blue" @click="confirm">
          {{ showDynamicComponent.confirmButton }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConfirmationComponent',
  props: {
    showDynamicComponent: {
      type: Object
    }
  },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    dialogVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.updateVisible(bool);
      if ((bool = false)) {
        this.$emit('changeVisible', false);
      }
    }
  },
  methods: {
    close() {
      this.$emit('changeVisible', false);
    },
    updateVisible(bool) {
      this.$emit('update:dialogVisible', bool);
    },
    confirm() {
      this.$emit('confirm');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/security.scss';
.footer {
  padding-top: 16px;
  .footer_body {
    display: flex;
    flex-direction: row;
    gap: 20px;
    /deep/ .el-button {
      border-radius: 8px;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      padding: 8px 12px;
    }
  }
}
.word_break {
  word-break: normal;
}
</style>
