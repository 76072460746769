<template>
    <div class="success" @close="close">
      <img src="@/assets/images/dialog_success.png" alt="" />
      <p class="text_black">
        {{ successNote.title }}
      </p>
      <p class="gray_text f-14 fw-400" v-html="successNote.desc"></p>
    </div>
  </template>
  <script>
  export default {
    name: 'SuccessComponent',
    props: ['dialogVisible', 'successNote'],
    data() {
      return {
        visible: false
      };
    },
    watch: {
      dialogVisible(bool) {
        this.visible = bool;
      },
      visible(bool) {
        this.updateVisible(bool);
      }
    },
    methods: {
      close() {
        this.$emit('confirm');
      },
      updateVisible(bool) {
        this.$emit('update:dialogVisible', bool);
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  @import '@/assets/css/pages/security.scss';

  /deep/ .el-dialog__header {
    padding: 0px;
  }

  .success {
    img {
      width: 70px;
    }

    p {
      margin-top: 20px;
      line-height: 19.6px;
      word-break: break-word;

      &.text_black {
        color: #ffffff;
        gap: 10px;
        font-weight: 700;
        font-size: 16px;
      }
    }

  }
</style>