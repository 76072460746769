import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'

const searchAppImg = require('@/assets/images/security/auth_app.png')

export function totpFirstChinaTip() {
  /* 最新 */
  const driverObj = driver()
  driverObj.highlight({
    element: '#totp_wrap_box',
    popover: {
      className: 'totp_guide_class',
      description: `
        <div class="left_tip">
        <div class="tip_top">
        <div class="search_img">
        <img src="${searchAppImg}" alt="" />
        </div>
        </div>
        <div class="tip_bottom">
        <div class="tip_text">
          新增 Authenticator 验证，<br />
          可以在微信小程序中搜索 Google Authenticator，<br />
          开启多一重保障<br />
        </div>
        <el-button class="btn_blue" id="experience-button"> 立即体验 </el-button>
        </div>
        </div>
    `,
      allowClose: true,
      side: 'left',
      align: 'end',
      position: 'bottom'
    },
  })

  // 添加点击事件处理程序
  const closeButton = document.getElementById('experience-button')
  closeButton.addEventListener('click', () => {
    // 关闭引导页
    driverObj.destroy()
  })
  const totpAreaButton = document.getElementById('totp_wrap_box')
  totpAreaButton.addEventListener('click', () => {
    driverObj.destroy()
  })
}
