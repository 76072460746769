<template>
  <div>
    <div class="title">
      <slot name="header">
        <p class="fw-800 f-16">
          {{ $t('security.changeEmail') }}
        </p>
      </slot>
    </div>
    <div class="body">
      <div class="word_break">
        <el-form label-position="top" :model="changeEmailForm" :rules="rulesChangeEmail" ref="changeEmailForm">
          <el-form-item :label="$t('changePw.field.currPw')" prop="currentPass">
            <el-input type="password" v-model="changeEmailForm.currentPass" autocomplete="off" show-password clearable>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('security.emailTitle')" prop="email">
            <el-input v-model="changeEmailForm.email" autocomplete="off" clearable> </el-input>
          </el-form-item>
          <VerificationCode
            ref="verificationCode"
            namespace="CP_UPDATE_NEW_EMAIL"
            :sendEmailProp="this.changeEmailForm.email"
            :showComponent="showComponent"
            :needEmail="true"
            :verifyError="verifyError"
          />
        </el-form>
      </div>
    </div>
    <div class="footer">
      <div class="footer_body">
        <el-button @click="close" class="btn-default" data-testid="changePw">
          {{ $t('common.button.cancel') }}
        </el-button>
        <el-button class="btn_blue" @click="submitForm('changeEmailForm')" data-testid="changePw">
          {{ $t('common.button.confirm') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { validateEmail } from '@/util/validation';
import { apiChangeEmail, apiCheckPassword } from '@/resource';
import VerificationCode from '@/components/security/VerificationCode.vue';
import { mapVerificationOperation, mapReverifiedBody } from '@/components/security/Security.js';
import { md5, rsa } from '@/util/encrypt';
export default {
  name: 'ChangeEmailComponent',
  props: ['dialogVisible'],
  components: { VerificationCode },
  data() {
    const validateCurrentPass = (rule, value, callback) => {
      if (value === '' || value.length < 1) {
        callback(new Error(this.$t('common.formValidation.currPwReq')));
      } else {
        apiCheckPassword({
          currentPass: md5(value)
        }).then(resp => {
          if (!resp.data) {
            callback(new Error(this.$t('common.formValidation.currPwWrong')));
          } else {
            callback();
          }
        });
      }
    };
    return {
      visible: false,
      verifyError: null,
      rulesChangeEmail: {
        currentPass: [{ validator: validateCurrentPass, trigger: ['blur'] }],
        email: [
          {
            required: true,
            message: this.$t('common.formValidation.emailReq'),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ],
        verification: [
          {
            required: true,
            message: this.$t('security.verificationRequired'),
            trigger: 'blur'
          }
        ]
      },
      changeEmailForm: {
        currentPass: '',
        email: '',
        verification: ''
      },
      showComponent: {}
    };
  },
  mounted() {
    this.showComponent = mapVerificationOperation('new-email');
  },
  watch: {
    dialogVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.updateVisible(bool);
    },
    'changeEmailForm.email'() {
      this.$refs.verificationCode.$refs.form.resetFields();
    }
  },
  methods: {
    close() {
      this.$refs.changeEmailForm.resetFields();
      this.$refs.verificationCode.$refs.form.resetFields();
      this.$emit('changeVisible', false);
    },
    updateVisible(bool) {
      this.$emit('update:dialogVisible', bool);
    },
    confirm() {
      this.$refs.verificationCode.$refs.form.resetFields();
      this.$emit('confirm');
    },
    submitForm() {
      const [emailForm, getVerifyCodeComponent] = [this.$refs.changeEmailForm, this.$refs.verificationCode];
      const [verifyEmailMethod, verifyCodeMethod] = [emailForm.validate(), getVerifyCodeComponent.submitForm()];
      Promise.all([verifyEmailMethod, verifyCodeMethod]).then(async valid => {
        if (valid) {
          const getVerifyCode = getVerifyCodeComponent.getFormDataOfChild();
          const requestBody = {
            ...getVerifyCode,
            email: rsa(this.changeEmailForm.email),
            password: md5(this.changeEmailForm.currentPass)
          };
          apiChangeEmail(requestBody).then(resp => {
            if (resp.data.code === 0) {
              this.verifyError = null;
              this.confirm();
            } else if (resp.data.code === 647) {
              this.verifyError = this.$t('responseMsg.647');
            } else if (resp.data.code == 1201 || resp.data.code == 1202) {
              const usedRequest = mapReverifiedBody('modify-email', resp);
              this.$emit('reauthenticate', usedRequest);
            } else {
              this.$refs.verificationCode.isSendEmail = false;
            }
          });
        } else return false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/security.scss';
.send_code {
  width: auto;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
}
.code_node {
  margin-top: 8px;
}
/deep/ .is-error {
  .el-form-item__content {
    .code_node {
      display: none;
    }
  }
}
.footer {
  padding-top: 16px;
  .footer_body {
    display: flex;
    gap: 20px;
    .el-button {
      border-radius: 8px;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      padding: 8px 12px;
    }
  }
}
</style>
