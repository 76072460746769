<template>
  <el-dialog
    @keydown.enter.native.prevent
    id="verificationDialog"
    :visible.sync="visible"
    @close="closeCurrDialog"
    :close-on-click-modal="false"
  >
    <div slot="title">
      <slot name="header"> </slot>
    </div>
    <VerificationComponent
      ref="verificationComponent"
      :action="action"
      @changeVisible="closeDialog"
      :usedAuthTypes="usedAuthTypes"
      @confirm="confirmDialog"
    />
    <div slot="footer"></div>
  </el-dialog>
</template>
<script>
import VerificationComponent from '@/components/security/VerificationComponent.vue';
export default {
  name: 'VerificationDialog',
  props: ['dialogVisible', 'usedAuthTypes', 'action'],
  components: { VerificationComponent },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    dialogVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.updateVisible(bool);
    }
  },
  methods: {
    closeCurrDialog() {
      this.$refs.verificationComponent.close();
    },
    closeDialog() {
      this.$emit('changeVisible', false);
    },
    updateVisible(bool) {
      this.$emit('update:dialogVisible', bool);
    },
    confirmDialog() {
      this.$emit('confirm', this.action);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/security.scss';
#verificationDialog {
  /deep/ .el-dialog {
    margin-top: 15vh !important;
    border-radius: 16px;
    text-align: start;
    max-width: 480px;
    padding: 40px 40px;
    height: auto;
  }
  /deep/ .el-dialog__header {
    padding: 0px;
    margin: 0px;
  }
  /deep/ .el-dialog__body {
    font-size: 14px;
    font-weight: 400;
    padding: 0px;
    padding-bottom: 0px;
  }
  /deep/ .is-error {
    .el-form-item__content {
      .code_node {
        display: none;
      }
    }
  }
  /deep/ .el-dialog__footer {
    padding: 0px;
  }
  .word_break {
    word-break: normal;
  }
  /deep/ .el-form-item__label {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    padding: 0 0 12px;
  }
  /deep/ .el-input__inner {
    height: 40px;
    padding-right: 55px;
    &:focus {
      border: 1px solid #3eadff;
    }
  }

  /deep/ .el-form-item {
    margin-bottom: 20px;
  }
}
</style>
