<template>
  <div id="securityManagement">
    <div class="content_box">
      <div class="inner_max">
        <h3>{{ $t('menu.securityManagement') }}</h3>
        <div class="sm_main">
          <div class="main_white">
            <AuthenticationNote :showButton="false" />
            <h1 class="fw-600 f-16 main_title">{{ $t('security.authenticationMethod') }}</h1>
            <div class="sm_main_body">
              <!--email-->
              <div class="authentication_box">
                <div class="authentication_main">
                  <div class="main_box left">
                    <div class="title_status">
                      <p class="f-16 fw-500 box_title">{{ $t('common.field.emailAdd') }}</p>
                      <div v-if="emailVerified" class="verify_box">
                        <img src="@/assets/images/security/checked.png" alt="" />
                        <p class="fw-400 f-12">{{ $t('security.isVerified') }}</p>
                      </div>
                      <div v-else class="verify_box">
                        <img src="@/assets/images/security/unchecked.png" alt="" />
                        <p class="fw-400 f-12">{{ $t('security.isNotVerified') }}</p>
                      </div>
                    </div>
                    <div class="fw-400 f-14">{{ email }}</div>
                    <div class="fw-400 f-12 gray_text" v-if="emailUpdateDate">
                      {{ `${$t('security.lastUpdate')}: ${emailUpdateDate}` }}
                    </div>
                  </div>
                  <div class="right">
                    <el-button
                      v-if="emailVerified"
                      @click="actionTaken('modify-email')"
                      data-testid="modify"
                      size="small"
                      :class="`${langClass}`"
                    >
                      {{ $t('security.modify') }}
                    </el-button>
                    <el-button v-else @click="verifyEmail()" data-testid="verify" size="small" :class="`${langClass}`">
                      {{ $t('security.verify') }}
                    </el-button>
                  </div>
                </div>
                <div class="border"></div>
                <div class="switch_status f-14 fw-400">
                  <div class="switch_status_sub" v-for="(item, index) in dynamicSwitches['email']" :key="index">
                    <p>{{ item.translateValue }}</p>
                    <el-switch
                      :disabled="item.disabled"
                      v-model="item.value"
                      @change="changeSwitch($event, 'email', item.authType, item.factorAuth)"
                    ></el-switch>
                  </div>
                  <div class="switch_status_sub">
                    <p>{{ $t('security.loginLocationChange') }}</p>
                    <el-switch v-model="ipChangeWarn" @change="changeIpWarningChange"></el-switch>
                  </div>
                </div>
              </div>
              <!--password-->
              <div class="authentication_box">
                <div class="authentication_main">
                  <div class="main_box left">
                    <p class="f-16 fw-500 box_title">{{ $t('security.password') }}</p>
                    <div class="fw-400 f-12 gray_text" v-if="passwordUpdateDate">
                      {{ `${$t('security.lastUpdate')}: ${passwordUpdateDate}` }}
                    </div>
                  </div>
                  <div class="right">
                    <el-button
                      size="small"
                      @click="actionTaken('modify-password')"
                      data-testid="modify"
                      :class="`${langClass}`"
                    >
                      {{ $t('security.modify') }}
                    </el-button>
                  </div>
                </div>
              </div>
              <!--totp-->
              <div id="totp_wrap_box" class="authentication_box">
                <div class="authentication_main">
                  <div class="main_box left">
                    <div class="title_status">
                      <p class="f-16 fw-500 box_title">{{ $t('security.securityAuthenticatorApp') }}</p>
                      <div v-if="totpVerified" class="verify_box">
                        <img src="@/assets/images/security/checked.png" alt="" />
                        <p class="fw-400 f-12">{{ $t('security.isVerified') }}</p>
                      </div>
                      <div v-else class="verify_box">
                        <img src="@/assets/images/security/unchecked.png" alt="" />
                        <p class="fw-400 f-12">{{ $t('security.isNotVerified') }}</p>
                      </div>
                    </div>
                    <div class="fw-400 f-12 gray_text" v-if="totpUpdateDate">
                      {{ `${$t('security.lastUpdate')}: ${totpUpdateDate}` }}
                    </div>
                  </div>
                  <div class="right">
                    <el-button
                      v-if="totpVerified"
                      size="small"
                      @click="actionTaken('modify-auth-2fa')"
                      data-testid="modify"
                      :class="`${langClass}`"
                    >
                      {{ $t('security.modify') }}
                    </el-button>
                    <el-button
                      v-else
                      size="small"
                      @click="actionTaken('enable-auth-2fa')"
                      data-testid="enable"
                      :class="`${langClass}`"
                    >
                      {{ $t('security.enable') }}
                    </el-button>
                  </div>
                </div>
                <div v-if="dynamicSwitches['totp'] && dynamicSwitches['totp'].length > 0" class="border"></div>
                <div class="switch_status f-14 fw-400">
                  <div class="switch_status_sub" v-for="(item, index) in dynamicSwitches['totp']" :key="index">
                    <p>{{ item.translateValue }}</p>
                    <el-switch
                      :disabled="item.disabled"
                      v-model="item.value"
                      @change="changeSwitch($event, 'totp', item.authType, item.factorAuth)"
                    ></el-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--dialog-->
      <el-dialog
        @keydown.enter.native.prevent
        @close="closeDialog"
        :id="currentComponent.class"
        :visible="dialogVisibility"
        :showCancel="true"
        center
        class="dialog"
        top="0"
        :close-on-click-modal="false"
      >
        <div slot="title">
          <slot name="header"> </slot>
        </div>
        <div class="dialog-body">
          <slot>
            <component
              ref="component"
              :is="currentComponent.component"
              :showDynamicComponent="showDynamicComponent"
              :usedAuthTypes="usedAuthTypes"
              :action="showDynamicComponent.factorAuth"
              :successNote="showDynamicComponent.success"
              @confirm="confirmChanges"
              @changeVisible="closeDialog"
              @reauthenticate="requireReauthenticate"
              :showDays="showDays"
          /></slot>
        </div>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>
      <VerificationDialog
        ref="verification"
        :dialogVisible.sync="verificationVisibility"
        :usedAuthTypes="usedAuthTypes"
        :action="showDynamicComponent.factorAuth"
        @changeVisible="updateVerificationVisible"
        @confirm="confirmVerification"
      ></VerificationDialog>
    </div>
  </div>
</template>
<script>
import ChangeAuthenticatorComponent from '@/components/security/ChangeAuthenticatorComponent.vue';
import ConfirmationComponent from '@/components/security/ConfirmationComponent.vue';
import VerificationDialog from '@/components/security/VerificationDialog.vue';
import SuccessComponent from '@/components/security/SuccessComponent.vue';
import ChangePasswordComponent from '@/components/security/ChangePasswordComponent.vue';
import ChangeEmailComponent from '@/components/security/ChangeEmailComponent.vue';
import {
  mapOperation,
  mapVariable,
  mapSwitchComponent,
  mapVerifiedAuth,
  mapReverifiedBody
} from '@/components/security/Security.js';
import AuthenticationNote from '@/components/security/AuthenticationNote';
import {
  apiSubmitResetChangeIpWarn,
  apiGetCurrentSecurity,
  apiGetSecurityInfo,
  apiChangeSwitch,
  apiGetMultiFactorAuth,
  apiVerifyEmail,
  apiInfo
} from '@/resource';
import moment from 'moment';
import helper from '@/util/signinHelper.js';
import { countryCodeEnum } from '@/constants/country';
import { totpFirstChinaTip } from '@/util/totpFirstChinaTip';
export default {
  props: {},
  components: {
    ConfirmationComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    SuccessComponent,
    ChangeAuthenticatorComponent,
    VerificationDialog,
    AuthenticationNote
  },
  data() {
    return {
      dialogVisibility: false,
      verificationVisibility: false,
      showDays: null,
      showDynamicComponent: {},
      usedAuthTypes: [],
      ipChangeWarn: false,
      email: '',
      emailUpdateDate: null,
      passwordUpdateDate: null,
      totpUpdateDate: null,
      dynamicSwitches: {
        email: [],
        totp: []
      },
      currentComponent: {},
      isReauthenticate: false,
      actionComponentMap: {
        'modify-password': {
          class: 'ChangePasswordComponent',
          component: ChangePasswordComponent
        },
        'modify-email': {
          class: 'ChangeEmailComponent',
          component: ChangeEmailComponent
        },
        'enable-auth-2fa': {
          class: 'ChangeAuthenticatorComponent',
          component: ChangeAuthenticatorComponent
        },
        'modify-auth-2fa': {
          class: 'ChangeAuthenticatorComponent',
          component: ChangeAuthenticatorComponent
        }
      }
    };
  },
  watch: {},
  computed: {
    emailVerified() {
      return this.checkedAuthStatus.filter(item => item == mapVariable['email']).length > 0;
    },
    totpVerified() {
      return this.checkedAuthStatus.filter(item => item == mapVariable['totp']).length > 0;
    },
    checkedAuthStatus() {
      return this.$store.state.common.checkedAuthStatus || [];
    },
    langClass() {
      return ['id', 'ru', 'de'].includes(this.$store.state.common.lang)
        ? `${this.$store.state.common.lang}_buttons`
        : '';
    }
  },
  async mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      await apiGetSecurityInfo().then(resp => {
        if (resp.data.code == 0) {
          const usedValue = resp.data.data;
          this.ipChangeWarn = usedValue.ipChangeSwitch;
          this.email = usedValue.email;
          this.emailUpdateDate = this.formatTime(usedValue.emailUpdateDate);
          this.passwordUpdateDate = this.formatTime(usedValue.passwordUpdateDate);
          this.totpUpdateDate = this.formatTime(usedValue.totpUpdateDate);
          this.$store.commit('common/setCheckedAuthStatus', usedValue.authStatus);
          this.$store.commit('common/setEmailMasked', usedValue.email);

          if (!usedValue.mandatoryFunctions) {
            // 判空
            usedValue.mandatoryFunctions = [];
          }
          //do ignore switches except login, and login.
          //BE mention will do enhancement in the future (won't return in the future)
          if (usedValue.functionAuthMethod) {
            this.dynamicSwitches = Object.keys(usedValue.functionAuthMethod).reduce((acc, functionName) => {
              usedValue.functionAuthMethod[functionName].forEach(authMethod => {
                if (!acc[authMethod]) {
                  acc[authMethod] = [];
                }
                const tempMapping = mapSwitchComponent(functionName, authMethod);
                if (tempMapping != null) {
                  const usedObject = {
                    authType: functionName,
                    translateValue: tempMapping ? tempMapping.title : '',
                    disabled:
                      usedValue.mandatoryFunctions.filter(item => item == functionName).length > 0 ||
                      !mapVerifiedAuth(authMethod, usedValue.authStatus),
                    value: usedValue[tempMapping.usedVariable][authMethod] || false,
                    factorAuth: tempMapping.factorAuth
                  };
                  acc[authMethod].push(usedObject);
                }
              });
              return acc;
            }, {});
          }
          this.getCountryOrNationality();
        }
      });
    },
    async getDays(factor) {
      //get to show the related confirmation dialogs
      const factorAuth =
        factor == 'modify-password'
          ? 'updatePassword'
          : ['enable-auth-2fa', 'modify-auth-2fa'].includes(factor)
          ? 'changeAuthenticator'
          : 'updateUserLogin';
      await apiGetCurrentSecurity(factorAuth).then(resp => {
        this.showDays = resp.data.data.ruleContent ? resp.data.data.ruleContent.withdraw.limitTime : null;
      });
    },
    formatTime(timestamp) {
      if (!timestamp) {
        return null;
      }
      const localTime = moment(timestamp);
      const gmtOffset = localTime.format('Z');
      return localTime.format('YYYY/MM/DD HH:mm:ss') + ' GMT' + gmtOffset;
    },
    async actionTaken(factorAuth) {
      //modify actions
      await this.getDays(factorAuth);
      this.showDynamicComponent = mapOperation(factorAuth, this.showDays);
      if (factorAuth != 'enable-auth-2fa' && this.showDays != null) {
        this.currentComponent = {
          class: 'ConfirmationComponent',
          component: ConfirmationComponent
        };
        this.dialogVisibility = true;
      } else {
        this.currentComponent = {
          class: 'ConfirmationComponent',
          component: ConfirmationComponent
        };
        this.confirmChanges();
      }
    },
    async verifyEmail() {
      this.showDynamicComponent = {
        factorAuth: 'verify-email'
      };
      this.needAuthentication(this.showDynamicComponent.factorAuth);
    },
    async changeSwitch(value, authMethod, authFunction, factorAuth) {
      const requestBody = { authFunction: authFunction, authMethod: authMethod, authSwitch: value };
      this.showDynamicComponent = {
        factorAuth: factorAuth,
        action: factorAuth,
        request: requestBody
      };
      if (value == false) {
        const needAuthentication = await this.needAuthentication(factorAuth);
        if (!needAuthentication) {
          await this.updateSwitchApi(requestBody);
        }
      } else {
        await this.updateSwitchApi(requestBody);
      }
    },
    async changeIpWarningChange(value) {
      await apiSubmitResetChangeIpWarn({ changeIpWarn: value }).then(async resp => {
        if (resp.data.code == 0) {
          this.ipChangeWarn = value;
          this.$message({ message: this.$t('security.updateSuccess'), type: 'success' });
          await this.getInfo();
        }
      });
    },
    async updateSwitchApi(requestBody) {
      await apiChangeSwitch(requestBody).then(resp => {
        if (resp.data.code == 0) {
          this.$message({ message: this.$t('security.updateSuccess'), type: 'success' });
          this.getInfo();
        } else if (resp.data.code == 1201 || resp.data.code == 1202) {
          const usedRequest = mapReverifiedBody(this.showDynamicComponent.factorAuth, resp);
          this.requireReauthenticate(usedRequest);
        }
      });
    },
    async getRelatedAuthTypes(factorCode) {
      if (!factorCode) return;
      let tempAuthTypes = [];
      if (factorCode == 'verify-email') {
        tempAuthTypes = [
          {
            type: 'email',
            authenticated: false,
            verified: true
          }
        ];
        let usedRequiredAuths = this.$store.state.common.requiredAuths;
        usedRequiredAuths[factorCode] = ['email'];
        this.$store.commit('common/setRequiredAuths', usedRequiredAuths);
      } else {
        // 除verify-email之外 其他modify-email modify-password enable-auth-2fa modify-auth-2fa 都需要
        // 调用对应的getMultiAuthMethod接口获取其当前功能的验证方式
        await apiGetMultiFactorAuth(factorCode).then(resp => {
          if (resp.data.code == 0) {
            tempAuthTypes =
              resp.data.data.authType != null
                ? resp.data.data.authType.map(item => {
                    return {
                      type: item,
                      authenticated: false,
                      verified: mapVerifiedAuth(item, this.$store.state.common.checkedAuthStatus)
                    };
                  })
                : [];
            let usedRequiredAuths = this.$store.state.common.requiredAuths;
            usedRequiredAuths[factorCode] = resp.data.data.authType;
            this.$store.commit('common/setRequiredAuths', usedRequiredAuths);
          }
        });
      }
      return tempAuthTypes;
    },
    closeDialog() {
      if (this.currentComponent && this.currentComponent.class == 'SuccessComponent') {
        if (
          this.showDynamicComponent.factorAuth == 'modify-password' ||
          this.showDynamicComponent.factorAuth == 'modify-email'
        ) {
          helper.signOut(null,'modifyAccount');
        } else {
          this.getInfo();
        }
      }
      this.dialogVisibility = false;
    },
    async confirmChanges() {
      this.dialogVisibility = false; // 开启totp验证 无需展示出金限制弹框
      let usedAction = this.showDynamicComponent.factorAuth;
      switch (this.currentComponent.class) {
        case 'ConfirmationComponent':
          const needAuthentication = await this.needAuthentication(usedAction);
          if (!needAuthentication) {
            this.confirmVerification();
          }
          break;
        case 'ChangeEmailComponent':
        case 'ChangePasswordComponent':
        case 'ChangeAuthenticatorComponent': {
          this.currentComponent = {
            class: 'SuccessComponent',
            component: SuccessComponent
          };
          this.dialogVisibility = true;
          break;
        }
      }
    },
    resetSwitch() {
      if (
        this.showDynamicComponent.factorAuth == 'login-email-close' ||
        this.showDynamicComponent.factorAuth == 'login-totp-close'
      ) {
        const tempRequest = this.showDynamicComponent.request;
        this.dynamicSwitches[tempRequest.authMethod] = this.dynamicSwitches[tempRequest.authMethod].map(item => {
          return {
            ...item,
            value: item.authType == tempRequest.authFunction ? !tempRequest.authSwitch : item.value
          };
        });
      }
    },
    /** verification */
    requireReauthenticate(val) {
      this.isReauthenticate = true;
      this.usedAuthTypes = val;
      this.handleAuthentication();
    },
    updateVerificationVisible() {
      this.verificationVisibility = false;
      this.resetSwitch();
    },
    async confirmVerification() {
      //proceed with next dialog or action after verification
      this.verificationVisibility = false;
      let usedAction = this.showDynamicComponent.factorAuth;
      if (
        this.showDynamicComponent.factorAuth == 'login-email-close' ||
        this.showDynamicComponent.factorAuth == 'login-totp-close'
      ) {
        this.updateSwitchApi(this.showDynamicComponent.request);
      } else if (usedAction == 'verify-email') {
        this.dialogVisibility = false;
        apiVerifyEmail().then(async resp => {
          if (resp.data.code == 0) {
            this.$message({
              message: this.$t('security.verifiedSuccess'),
              type: 'success',
              duration: 5000
            });
            await this.getInfo();
          } else {
            return;
          }
        });
      } else {
        if (this.dialogVisibility == true && this.isReauthenticate) {
          this.$refs.component.submitForm();
        } else if (this.actionComponentMap.hasOwnProperty(usedAction)) {
          this.currentComponent = this.actionComponentMap[usedAction];
          this.dialogVisibility = true;
        }
      }
      this.isReauthenticate = false;
    },
    async needAuthentication(factorAuth) {
      //get related authentication required
      this.usedAuthTypes = await this.getRelatedAuthTypes(factorAuth);
      return this.handleAuthentication();
    },
    async handleAuthentication() {
      if (this.usedAuthTypes.every(item => item.verified == true) == false) {
        this.resetSwitch();
        this.showDynamicComponent = {
          title: this.$t('security.verificationTitle'),
          desc: this.$t('security.verificationDesc'),
          confirmButton: null,
          cancelButton: this.$t('common.button.confirm')
        };
        this.currentComponent = {
          class: 'ConfirmationComponent',
          component: ConfirmationComponent
        };
        this.dialogVisibility = true;
        return true;
      }
      if (this.usedAuthTypes && this.usedAuthTypes.length > 0) {
        this.verificationVisibility = true;
        return true;
      }
      return false;
    },
    getCountryOrNationality() {
      apiInfo().then(resp => {
        const result = resp.data.data;
        // ACM-26778-针对国籍为Chinese或居住国为中国 站内需要显示中国绑定验证器提示语
        if (result && (result.nationality == 'Chinese' || result.country == 'China')) {
          this.$store.commit('common/setBeforeLoginCountry', countryCodeEnum.CHINA);
          // 针对国籍或居住国为China的客户，首次进入CP安全中心页面时，框出新功能并在左侧弹出一次性引导
          this.judgeIsFirstTimeEnter();
        }
      });
    },
    // 判断用户是否首次进入该页面
    judgeIsFirstTimeEnter() {
      const isFirstTime = localStorage.getItem('isFirstTime');
      const userId = this.$store.state.common.userId;
      if (isFirstTime === null) {
        // 首次进入当前路由
        localStorage.setItem('isFirstTime', `[${userId}]`);
        totpFirstChinaTip();
      } else {
        try {
          let staorageRecords = JSON.parse(isFirstTime);
          if (!staorageRecords?.includes(userId)) {
            // 不同用户首次进入当前路由
            localStorage.setItem('isFirstTime', `[${[...staorageRecords, userId]}]`);
            totpFirstChinaTip();
          }
        } catch (error) {
          console.log('error');
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/security.scss';

/deep/ .el-dialog {
  margin-top: 15vh !important;
  border-radius: 16px;
  padding: 40px 40px;
  height: auto;
  max-height: 85vh;
  overflow-y: scroll;
}

/deep/ .el-dialog__header {
  padding: 0px;
  margin: 0px;
}

/deep/ .el-dialog__body {
  font-size: 14px;
  font-weight: 400;
  padding: 0px;
  padding-bottom: 0px;

  .el-dialog__body {
    text-align: start;
  }

  .title {
    padding-bottom: 20px;
    color: #ffffff;
  }

  .body {
    padding-bottom: 20px;
  }
}

/deep/ .el-dialog__footer {
  padding: 0px;
}

.verificationDialog /deep/ .el-dialog {
  max-width: 480px;
}

#ConfirmationComponent {
  /deep/ .el-dialog {
    max-width: 480px;

    .el-dialog__body {
      text-align: center;
    }
  }
}

#ChangePasswordComponent {
  /deep/ .el-dialog {
    max-width: 480px;
  }
}

#ChangeEmailComponent {
  /deep/ .el-dialog {
    max-width: 480px;
  }
}

#VerificationComponent {
  /deep/ .el-dialog {
    max-width: 480px;
  }
}

#ChangeAuthenticatorComponent {
  /deep/ .el-dialog {
    width: 852px;
  }
}

#SuccessComponent {
  /deep/ .el-dialog {
    padding: 40px 50px;
    max-width: 480px;

    .el-dialog__body {
      text-align: center;
    }
  }
}

.id_buttons {
  min-width: 120px !important;
}
.ru_buttons {
  min-width: 100px !important;
}
.de_buttons {
  min-width: 105px !important;
}
</style>
<style lang="scss">
@import '@/assets/css/pages/securityChinaTotpTip.scss';
</style>